export default class LabelGroup {
  constructor(groupType = '', filters = [], options = [], page = { current: 1, total: 1 }, isLoading = false) {
    this.groupType = groupType;
    this.options = options;
    this.page = page;
    this.filters = filters;
    this.isLoading = isLoading;
  }

  addFilters(newFilters) {
    this.filters = this.filters.concat(newFilters);
    return this;
  }
}
