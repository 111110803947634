var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("asterix-section", {
    attrs: { status: _vm.sectionStatus },
    scopedSlots: _vm._u(
      [
        !_vm.isSalesRole
          ? {
              key: "header-right",
              fn: function () {
                return [
                  _c(
                    "router-link",
                    { attrs: { to: _vm.newClusterRoute() } },
                    [
                      _c(
                        "sun-button",
                        {
                          staticClass:
                            "px-4 custom-p-1 text-xs bg-gray-700 hover:bg-gray-800",
                          attrs: { variant: "pill", color: "gray" },
                        },
                        [_vm._v(" + New Cluster ")]
                      ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            }
          : null,
        {
          key: "content",
          fn: function () {
            return [
              _c("ag-grid-vue", {
                key: `ClusterTable_${_vm.tableKey}`,
                staticClass: "ag-theme-alpine",
                staticStyle: { height: "500px" },
                attrs: {
                  "column-defs": _vm.headers,
                  "column-types": _vm.columnTypes,
                  pagination: "",
                  "animate-rows": "",
                  "server-side-infinite-scroll": "",
                  "row-model-type": _vm.rowModelType,
                  "suppress-agg-func-in-header": true,
                  "default-col-def": _vm.defaultColDef,
                  "pagination-page-size": _vm.paginationPageSize,
                  "cache-block-size": _vm.cacheBlockSize,
                },
                on: { "grid-ready": _vm.onGridReady },
              }),
            ]
          },
          proxy: true,
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }