<template>
  <span class="group-item">
    <span class="font-bold"> {{ group.id }} </span>
    {
    <span v-for="(label, i) in group.labels" :key="label.uid" class="label">
      <span>{{ label.label }}</span>
      <span v-if="i < group.labels.length - 1" class="italic font-medium uppercase operator">
        {{ group.operator }}
      </span>
    </span>
    }
    <span class="italic font-medium uppercase operator">
      {{ operator }}
    </span>
  </span>
</template>
<script>
export default {
  name: 'SegmentationGroupInline',
  props: {
    group: {
      type: Object,
      default: () => {},
    },
    operator: {
      type: String,
      default: '',
    },
  },
};
</script>
<style scoped>
.group-item:last-child span.operator {
  display: none;
}
.group-item:nth-last-child(2) span.operator {
  display: none;
}
</style>
