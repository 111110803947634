<template>
  <asterix-section>
    <template #header-title>Cluster Stats</template>
    <template #content>
      <div class="w-full p-6 bg-white rounded-lg shadow-md lg:p-12 mb-12">
        <div class="w-full flex flex-col md:flex-row">
          <sun-label-group text="Cluster ID:" class="w-full">
            <div v-if="isLoading" class="bg-gray-300 animate-pulse rounded h-5 w-32 py-1"></div>
            <p v-else-if="!firstCluster.detail">No data</p>
            <p v-else>{{ firstCluster.id }}</p>
          </sun-label-group>
          <sun-label-group text="Cluster Name:" class="w-full mt-8 md:mt-0">
            <div v-if="isLoading" class="bg-gray-300 animate-pulse rounded h-5 w-40 py-1"></div>
            <p v-else-if="!firstCluster.detail">No data</p>
            <p v-else>{{ firstCluster.name }}</p>
          </sun-label-group>
        </div>
        <div class="w-full flex mt-8">
          <sun-label-group text="Cluster Details:" class="w-full">
            <div v-if="isLoading" class="bg-gray-300 animate-pulse rounded h-5 w-1/3 py-1"></div>
            <p v-else-if="!firstCluster.detail">No data</p>
            <p v-else>{{ firstCluster.detail }}</p>
          </sun-label-group>
        </div>
        <div class="w-full flex mt-12">
          <sun-label-group text="Cluster total request:" class="w-full text-md">
            <div
              v-if="firstCluster && firstCluster.data?.total"
              class="request-group w-full flex flex-col sm:flex-row mt-2 justify-between px-4"
            >
              <cluster-stats-info
                v-for="period in periodicity"
                :key="period.id"
                class="request"
                :is-loading="isLoading"
                :title="period.label"
                :text-value="firstCluster?.data.total[period.id].value || null"
                :pill-value="firstCluster?.data.total[period.id].score || null"
              />
            </div>
            <div v-else-if="isLoading" class="request-group w-full flex flex-col sm:flex-row justify-between px-2 mt-2">
              <cluster-stats-info
                v-for="period in periodicity"
                :key="period.id"
                class="request"
                :is-loading="isLoading"
              />
            </div>
            <div v-else>No data</div>
          </sun-label-group>
        </div>
        <div class="w-full flex mt-8">
          <sun-label-group text="Cluster detail:" class="w-full">
            <div v-if="firstCluster && firstCluster.data?.detail" class="cluster-details flex flex-col w-full">
              <div
                v-for="detail in firstCluster.data.detail"
                :key="detail.id"
                class="detail w-full mt-2 pt flex flex-col p-2"
              >
                <p class="text-left text-sm font-bold text-gray-700">{{ detail.label }}</p>
                <div class="request-group w-full flex flex-col sm:flex-row justify-between px-2 mt-2">
                  <cluster-stats-info
                    v-for="period in periodicity"
                    :key="period.id"
                    class="request"
                    :is-loading="isLoading"
                    :title="period.label"
                    :text-value="detail.data[period.id]?.value || null"
                    :pill-value="detail.data[period.id]?.score || null"
                  />
                </div>
              </div>
            </div>
            <div v-else-if="isLoading" class="request-group w-full flex flex-col sm:flex-row justify-between px-2 mt-2">
              <cluster-stats-info
                v-for="period in periodicity"
                :key="period.id"
                class="request"
                :is-loading="isLoading"
              />
            </div>
            <div v-else>No data</div>
          </sun-label-group>
        </div>
      </div>
    </template>
  </asterix-section>
</template>

<script>
import { mapGetters } from 'vuex';
import { ACTIVE_CLIENT } from '@/store/modules/context/keys';
import AsterixSection from '@/components/templates/AsterixSection';
import ClusterStatsInfo from '@/components/molecules/modules/contextual/ClusterStatsInfo.vue';
import { getClusterStats } from '@/services/modules/contextual/analytics/getClusterStats';

export default {
  name: 'ClusterStats',
  components: {
    AsterixSection,
    ClusterStatsInfo,
  },
  data() {
    return {
      isLoading: true,
      clusters: [],
      periodicity: [
        { id: 'yesterday', label: 'Yesterday' },
        { id: 'week', label: '7 Days' },
        { id: 'month', label: '30 Days' },
        { id: 'trimester', label: '90 Days' },
      ],
    };
  },
  computed: {
    ...mapGetters({
      activeClient: ACTIVE_CLIENT,
    }),
    clusterId() {
      return this.$route.params.clusterId;
    },
    firstCluster() {
      return this.clusters[0];
    },
  },
  async mounted() {
    await this.loadInitialCluster();
  },
  methods: {
    async loadInitialCluster() {
      this.clusters = await getClusterStats(this.activeClient.id, this.clusterId);
      this.isLoading = false;
    },
  },
};
</script>
<style scoped>
.request {
  @apply border-r rounded-lg;
}
@media (min-width: 640px) {
  .request {
    @apply border-r-0 rounded-none;
  }
}
.request-group .request:last-child {
  @apply border-r rounded-r-lg;
}
.request-group .request:first-child {
  @apply rounded-l-lg;
}
.cluster-details .detail:first-child {
  @apply mt-0;
}
:deep(.sun-label) {
  @apply text-base text-gray-800;
}
</style>
