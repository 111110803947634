var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isSunmediaGeneral
    ? _c(
        "span",
        {
          staticClass: "truncate w-full text-left capitalize",
          attrs: { title: _vm.value },
        },
        [_vm._v(" " + _vm._s(_vm.value || "N/A") + " ")]
      )
    : _c(
        "span",
        {
          staticClass:
            "truncate w-full text-left underline cursor-pointer capitalize",
          attrs: { title: _vm.value },
          on: { click: _vm.goToSaleforce },
        },
        [_vm._v(" " + _vm._s(_vm.value || "N/A") + " ")]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }