import AnalyticCluster from '@/entities/contextual/AnalyticCluster';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';
import { CLIENT_RESOURCE, ANALYTICS_RESOURCE } from '@/services/keys';
import { api } from '.';

export async function getClusterStats(clientId, clusterId, params = new QueryParamsBuilder()) {
  const partialUrl = api.createUrl({
    [CLIENT_RESOURCE]: clientId,
    [ANALYTICS_RESOURCE]: undefined,
  });
  params.addFilter('cluster.id', clusterId);

  const { data } = await api.get(partialUrl, params.buildWithoutPage());
  return data.data.map(cluster => {
    return new AnalyticCluster(
      cluster.clusterId,
      cluster.clusterName,
      cluster.clusterDetail,
      cluster.clusterData
        ? {
            total: cluster.clusterData.total,
            detail: Object.values(cluster?.clusterData.detail),
          }
        : null
    );
  });
}
