var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("asterix-section", {
    scopedSlots: _vm._u([
      {
        key: "header-title",
        fn: function () {
          return [_vm._v("Cluster Stats")]
        },
        proxy: true,
      },
      {
        key: "content",
        fn: function () {
          return [
            _c(
              "div",
              {
                staticClass:
                  "w-full p-6 bg-white rounded-lg shadow-md lg:p-12 mb-12",
              },
              [
                _c(
                  "div",
                  { staticClass: "w-full flex flex-col md:flex-row" },
                  [
                    _c(
                      "sun-label-group",
                      { staticClass: "w-full", attrs: { text: "Cluster ID:" } },
                      [
                        _vm.isLoading
                          ? _c("div", {
                              staticClass:
                                "bg-gray-300 animate-pulse rounded h-5 w-32 py-1",
                            })
                          : !_vm.firstCluster.detail
                          ? _c("p", [_vm._v("No data")])
                          : _c("p", [_vm._v(_vm._s(_vm.firstCluster.id))]),
                      ]
                    ),
                    _c(
                      "sun-label-group",
                      {
                        staticClass: "w-full mt-8 md:mt-0",
                        attrs: { text: "Cluster Name:" },
                      },
                      [
                        _vm.isLoading
                          ? _c("div", {
                              staticClass:
                                "bg-gray-300 animate-pulse rounded h-5 w-40 py-1",
                            })
                          : !_vm.firstCluster.detail
                          ? _c("p", [_vm._v("No data")])
                          : _c("p", [_vm._v(_vm._s(_vm.firstCluster.name))]),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "w-full flex mt-8" },
                  [
                    _c(
                      "sun-label-group",
                      {
                        staticClass: "w-full",
                        attrs: { text: "Cluster Details:" },
                      },
                      [
                        _vm.isLoading
                          ? _c("div", {
                              staticClass:
                                "bg-gray-300 animate-pulse rounded h-5 w-1/3 py-1",
                            })
                          : !_vm.firstCluster.detail
                          ? _c("p", [_vm._v("No data")])
                          : _c("p", [_vm._v(_vm._s(_vm.firstCluster.detail))]),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "w-full flex mt-12" },
                  [
                    _c(
                      "sun-label-group",
                      {
                        staticClass: "w-full text-md",
                        attrs: { text: "Cluster total request:" },
                      },
                      [
                        _vm.firstCluster && _vm.firstCluster.data?.total
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "request-group w-full flex flex-col sm:flex-row mt-2 justify-between px-4",
                              },
                              _vm._l(_vm.periodicity, function (period) {
                                return _c("cluster-stats-info", {
                                  key: period.id,
                                  staticClass: "request",
                                  attrs: {
                                    "is-loading": _vm.isLoading,
                                    title: period.label,
                                    "text-value":
                                      _vm.firstCluster?.data.total[period.id]
                                        .value || null,
                                    "pill-value":
                                      _vm.firstCluster?.data.total[period.id]
                                        .score || null,
                                  },
                                })
                              }),
                              1
                            )
                          : _vm.isLoading
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "request-group w-full flex flex-col sm:flex-row justify-between px-2 mt-2",
                              },
                              _vm._l(_vm.periodicity, function (period) {
                                return _c("cluster-stats-info", {
                                  key: period.id,
                                  staticClass: "request",
                                  attrs: { "is-loading": _vm.isLoading },
                                })
                              }),
                              1
                            )
                          : _c("div", [_vm._v("No data")]),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "w-full flex mt-8" },
                  [
                    _c(
                      "sun-label-group",
                      {
                        staticClass: "w-full",
                        attrs: { text: "Cluster detail:" },
                      },
                      [
                        _vm.firstCluster && _vm.firstCluster.data?.detail
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "cluster-details flex flex-col w-full",
                              },
                              _vm._l(
                                _vm.firstCluster.data.detail,
                                function (detail) {
                                  return _c(
                                    "div",
                                    {
                                      key: detail.id,
                                      staticClass:
                                        "detail w-full mt-2 pt flex flex-col p-2",
                                    },
                                    [
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "text-left text-sm font-bold text-gray-700",
                                        },
                                        [_vm._v(_vm._s(detail.label))]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "request-group w-full flex flex-col sm:flex-row justify-between px-2 mt-2",
                                        },
                                        _vm._l(
                                          _vm.periodicity,
                                          function (period) {
                                            return _c("cluster-stats-info", {
                                              key: period.id,
                                              staticClass: "request",
                                              attrs: {
                                                "is-loading": _vm.isLoading,
                                                title: period.label,
                                                "text-value":
                                                  detail.data[period.id]
                                                    ?.value || null,
                                                "pill-value":
                                                  detail.data[period.id]
                                                    ?.score || null,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ]
                                  )
                                }
                              ),
                              0
                            )
                          : _vm.isLoading
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "request-group w-full flex flex-col sm:flex-row justify-between px-2 mt-2",
                              },
                              _vm._l(_vm.periodicity, function (period) {
                                return _c("cluster-stats-info", {
                                  key: period.id,
                                  staticClass: "request",
                                  attrs: { "is-loading": _vm.isLoading },
                                })
                              }),
                              1
                            )
                          : _c("div", [_vm._v("No data")]),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }