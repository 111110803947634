var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    {
      staticClass:
        "px-4 py-1 leading-tight align-middle inline-flex items-center rounded-full",
      class: [_vm.bgColor],
    },
    [
      _c(
        "span",
        {
          staticClass: "text-xs font-bold whitespace-no-wrap",
          class: [_vm.textColor],
        },
        [_vm._t("default")],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }