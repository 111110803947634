var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    { staticClass: "truncate w-full text-left" },
    [
      _c(
        "sun-popover",
        {
          attrs: { position: "right", "delay-hide": 0, "delay-show": 0 },
          scopedSlots: _vm._u([
            {
              key: "popover",
              fn: function () {
                return [
                  _c(
                    "div",
                    {
                      staticClass:
                        "bg-white text-xs text-gray-800 px-2 py-1 border border-gray-400 rounded shadow-lg grid grid-cols-1",
                    },
                    [
                      _vm._l(_vm.groups, function (group, index) {
                        return _c("segmentation-group-inline", {
                          key: index,
                          staticClass: "my-1",
                          attrs: { group: group, operator: _vm.operator },
                        })
                      }),
                      _c("span", { staticClass: "my-1" }, [
                        _c("span", { staticClass: "font-bold" }, [
                          _vm._v("weather"),
                        ]),
                        _vm._v(" " + _vm._s(_vm.weatherLabel) + " "),
                      ]),
                    ],
                    2
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _vm._l(_vm.groups, function (group, index) {
            return _c("segmentation-group-inline", {
              key: index,
              attrs: { group: group, operator: _vm.operator },
            })
          }),
          _vm.showWeather
            ? _c("span", [
                _c("span", { staticClass: "font-bold" }, [_vm._v("weather")]),
                _vm._v(" " + _vm._s(_vm.weatherLabel) + " "),
              ])
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }