var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "w-full flex items-center justify-center" },
    [
      _vm.isTextCell
        ? _c("text-cell", { attrs: { value: _vm.value } })
        : _vm.isNumericCell
        ? _c("numeric-cell", {
            attrs: { value: _vm.value, "remove-format-number": "" },
          })
        : _vm.isSegmentationCell
        ? _c("contextual-segmentation-cell", {
            attrs: { value: _vm.value, "row-data": _vm.rowData },
          })
        : _vm.isSaleforceCell
        ? _c("saleforce-cell", {
            attrs: { value: _vm.value, "row-data": _vm.rowData },
          })
        : _c("span", { staticClass: "bg-red-200" }, [
            _vm._v("--" + _vm._s(_vm.value)),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }