var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("sun-search-input", {
        attrs: { "class-input": "text-sm mb-2" },
        on: { search: _vm.onSearch },
      }),
      _c("target-options-list", {
        attrs: {
          includes: _vm.segmentsIncluded,
          excludes: _vm.segmentsExcluded,
          "is-loading": _vm.isLoading,
          "total-lines-loading": 5,
          "is-exclude-visible": "",
          "can-include": "",
          "can-exclude": "",
          options: _vm.labelGroup.options,
        },
        on: {
          include: function ($event) {
            return _vm.$emit("include", $event)
          },
          exclude: function ($event) {
            return _vm.$emit("exclude", $event)
          },
        },
      }),
      _c("sun-pagination", {
        attrs: {
          "current-page": _vm.labelGroup.page.current,
          "total-pages": _vm.labelGroup.page.total,
        },
        on: { change: _vm.onChangePage },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }