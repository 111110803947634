<template>
  <div>
    <sun-search-input class-input="text-sm mb-2" @search="onSearch" />
    <target-options-list
      :includes="segmentsIncluded"
      :excludes="segmentsExcluded"
      :is-loading="isLoading"
      :total-lines-loading="5"
      is-exclude-visible
      can-include
      can-exclude
      :options="labelGroup.options"
      @include="$emit('include', $event)"
      @exclude="$emit('exclude', $event)"
    />
    <sun-pagination
      :current-page="labelGroup.page.current"
      :total-pages="labelGroup.page.total"
      @change="onChangePage"
    />
  </div>
</template>
<script>
import TargetOptionsList from '@/components/organisms/shared/includes/TargetOptionsList.vue';

export default {
  name: 'LabelsSection',
  components: { TargetOptionsList },
  props: {
    labelGroup: { type: Object, default: () => {} },
    segmentsExcluded: { type: Array, default: () => [] },
    segmentsIncluded: { type: Array, default: () => [] },
    isLoading: { type: Boolean, default: false },
  },
  methods: {
    onSearch(labelSearched) {
      this.$emit('search', { value: labelSearched, option: this.labelGroup.groupType });
    },
    onChangePage(page) {
      this.$emit('onChangePage', { page: page.page, type: this.labelGroup.groupType });
    },
  },
};
</script>

<style></style>
