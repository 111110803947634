import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';
import { LABELS_RESOURCES } from '@/services/keys';
import { api } from '.';

export const getResources = () => ({
  [LABELS_RESOURCES]: undefined,
});

export async function getLabelList(params = new QueryParamsBuilder()) {
  const partialUrl = api.createUrl(getResources());

  const { data } = await api.get(partialUrl, params.build());
  return data;
}
