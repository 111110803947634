import { SALESFORCE_OPPORTUNITIES_RESOURCE } from '@/services/keys';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';
import contextualEntityParser from '@/entities/contextual/ContextualEntityParser';
import { getSalesforceOpportunitiesShared } from '@/services/shared/salesforceOpportunity';

export const getResources = () => ({
  [SALESFORCE_OPPORTUNITIES_RESOURCE]: undefined,
});

export function getSalesforceOpportunities(params = new QueryParamsBuilder(1, 200)) {
  params.itemsPerPage = 200;
  return getSalesforceOpportunitiesShared(contextualEntityParser, params);
}
