import { contextual } from '..';
import { getClusters } from './getClusters';
import { deleteCluster } from './deleteCluster';
import { createCluster } from './createCluster';
import { updateCluster } from './updateCluster';
import { getClusterById } from './getClusterById';

export const api = contextual;

export { getClusters, deleteCluster, createCluster, updateCluster, getClusterById };
