<template>
  <div class="flex flex-col mt-2 sm:mt-0 sm:w-1/4 p-4 border border-r-0">
    <p class="text-sm font-bold text-left text-gray-600">{{ title }}</p>
    <div v-if="isLoading" class="flex justify-between mt-5">
      <div class="bg-gray-300 animate-pulse rounded h-6 w-32 py-1"></div>
      <div class="bg-gray-300 animate-pulse rounded-full h-6 w-10 py-1"></div>
    </div>
    <div v-else class="flex justify-between mt-5">
      <span v-if="textValue" class="font-bold text-xl">{{ textValue | numFormatEs }}</span>
      <span v-else class="font-bold text-xl">N/A</span>
      <sun-pill v-if="pillValue !== null" :color="pillColor" bg-intensity="100" text-intensity="600">
        <div class="flex items-center">
          <span v-if="isEqual" class="w-4 h-4 mr-1">=</span>
          <arrow-downward-svg v-else :class="{ 'rotate-180': isPosive }" class="w-4 h-4 mr-1" />
          <span>{{ pillValue | percentage }}</span>
        </div>
      </sun-pill>
    </div>
  </div>
</template>
<script>
import SunPill from '@/components/atoms/SunPill/SunPill.vue';
import ArrowDownwardSvg from '@/components/icons/ArrowDownSvg.vue';

export default {
  name: 'ClusterStatsInfo',
  components: {
    SunPill,
    ArrowDownwardSvg,
  },
  props: {
    title: {
      type: String,
      default: () => 'Title',
    },
    textValue: {
      type: Number,
      default: () => 0,
    },
    pillValue: {
      type: Number,
      default: () => 0,
    },
    isLoading: {
      type: Boolean,
      defalut: () => true,
    },
  },
  computed: {
    isPosive() {
      return this.pillValue > 0;
    },
    isEqual() {
      return this.pillValue === 0;
    },
    pillColor() {
      if (this.isEqual) return 'yellow';
      return this.isPosive ? 'green' : 'red';
    },
  },
};
</script>
