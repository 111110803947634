import { CLIENT_RESOURCE, CLUSTERS_RESOURCE } from '@/services/keys';
import { api } from '.';

export function updateCluster(clientId, cluster) {
  const partialUrl = api.createUrl({
    [CLIENT_RESOURCE]: clientId,
    [CLUSTERS_RESOURCE]: cluster.id,
  });
  return api.update(partialUrl, cluster);
}
