export class Opportunity {
  constructor(
    id = '',
    name = '',
    state = '',
    currencyIsoCode = '',
    ownerId = '',
    created = null,
    updated = null,
    advertiserId = '',
    advertiserName = '',
    brandId = '',
    brandName = '',
    campaignLive = null,
    division = '',
    marca = '',
    office = '',
    region = '',
    start = null,
    end = null,
    primaryOpportunity = '',
    createdAt = null,
    updatedAt = null,
    ownerName = '',
    accountManager = '',
    recordTypeName = '',
    billingEntityName = ''
  ) {
    this.id = id;
    this.name = name;
    this.state = state;
    this.currencyIsoCode = currencyIsoCode;
    this.ownerId = ownerId;
    this.created = created;
    this.updated = updated;
    this.advertiserId = advertiserId;
    this.advertiserName = advertiserName;
    this.brandId = brandId;
    this.brandName = brandName;
    this.campaignLive = campaignLive;
    this.division = division;
    this.marca = marca;
    this.office = office;
    this.region = region;
    this.start = start;
    this.end = end;
    this.primaryOpportunity = primaryOpportunity;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.ownerName = ownerName;
    this.accountManager = accountManager;
    this.recordTypeName = recordTypeName;
    this.billingEntityName = billingEntityName;
  }

  static create(entity) {
    const attr = entity.attributes;
    return new Opportunity(
      entity.id,
      attr.name,
      attr.state,
      attr.currencyIsoCode,
      attr.ownerId,
      attr.created ? new Date(attr.created) : null,
      attr.updated ? new Date(attr.updated) : null,
      attr.advertiserId,
      attr.advertiserName,
      attr.brandId,
      attr.brandName,
      attr.campaignLive ? new Date(attr.campaignLive) : null,
      attr.division,
      attr.marca,
      attr.office,
      attr.region,
      attr.start ? new Date(attr.start) : null,
      attr.end ? new Date(attr.end) : null,
      attr.createdAt ? new Date(attr.createdAt) : null,
      attr.updatedAt ? new Date(attr.updatedAt) : null,
      attr.ownerName,
      attr.accountManager,
      attr.recordTypeName,
      attr.billingEntityName
    );
  }
}
