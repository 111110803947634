var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c("header", [
        _c("nav", [
          _c(
            "ul",
            { staticClass: "flex space-x-2 border-b" },
            _vm._l(_vm.tabs, function (tab) {
              return _c(
                "li",
                {
                  key: tab.title,
                  staticClass:
                    "relative inline-block px-1 py-3 text-sm font-semibold text-gray-500 border-b-2 border-transparent cursor-pointer select-none transition ease-in-out duration-150 hover:border-orange-600 hover:text-orange-600 hover:text-gray-600 hover:border-gray-300",
                  class: {
                    "text-orange-600 border-orange-600":
                      _vm.active === tab.title,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.onClickTab(tab)
                    },
                  },
                },
                [_vm._v(" " + _vm._s(tab.title) + " ")]
              )
            }),
            0
          ),
        ]),
      ]),
      _vm._l(_vm.tabs, function (tab) {
        return _c(
          "div",
          {
            key: tab.title,
            staticClass: "py-4 px-2",
            class: { hidden: _vm.active !== tab.title },
          },
          [
            _vm._t(
              tab?.id || tab.title.toLowerCase(),
              function () {
                return [_vm._v(" " + _vm._s(tab.title) + " ")]
              },
              { tab: tab }
            ),
          ],
          2
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }