<template>
  <div class="relative w-full">
    <div class="multi-range-slider">
      <div class="flex w-full justify-between mb-2">
        <span>{{ min }}</span>
        <span>{{ max }}</span>
      </div>
      <input
        id="input-left"
        v-model="leftValue"
        type="range"
        :min="min"
        :max="max"
        :step="step"
        @input="calculateLeftPercent"
        @change="changeValue"
      />
      <input
        id="input-right"
        v-model="rightValue"
        type="range"
        :min="min"
        :max="max"
        :step="step"
        @input="calculateRightPercent"
        @change="changeValue"
      />

      <div class="slider relative bg-gray-300 rounded h-2">
        <div class="track absolute"></div>
        <div class="range bg-orange-500" :style="rangePercentStyle"></div>
        <div class="thumb left bg-orange-500 w-5 h-5 rounded-full absolute mt-1" :style="leftPercentStyle"></div>
        <div class="thumb right bg-orange-500 w-5 h-5 rounded-full absolute mt-1" :style="rightPercentStyle"></div>
      </div>
    </div>
    <div class="flex w-full justify-around mt-3">
      <div class="flex flex-col items-center">
        <span class="font-bold text-left text-sm mr-1 sun-label">{{ titleValue }} min:</span>
        <span>{{ leftValue }}</span>
      </div>
      <div class="flex flex-col items-center">
        <span class="font-bold text-left text-sm mr-1 sun-label">{{ titleValue }} max:</span>
        <span>{{ rightValue }}</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'DoubleRangeSliderInput',
  props: {
    min: {
      type: String,
      default: '0',
    },
    max: {
      type: String,
      default: '100',
    },
    titleValue: {
      type: String,
      default: '',
    },
    minValue: { type: Number, default: 0 },
    maxValue: { type: Number, default: 100 },
    step: { type: [String, Number], default: 1 },
    emptyValue: { type: Boolean, default: true },
  },
  data: () => ({
    leftPercent: 0,
    leftValue: 0,
    rightPercent: 0,
    rightValue: 100,
  }),
  computed: {
    leftPercentStyle() {
      return { left: `${this.leftPercent}%` };
    },
    rightPercentStyle() {
      return { right: `${this.rightPercent}%` };
    },
    rangePercentStyle() {
      return { ...this.leftPercentStyle, ...this.rightPercentStyle };
    },
    parsedMin() {
      return parseInt(this.min);
    },
    parsedMax() {
      return parseInt(this.max);
    },
  },
  mounted() {
    this.leftValue = this.minValue;
    this.rightValue = this.maxValue;
    this.inputLeftDown();
    this.inputRightDown();
  },
  methods: {
    inputLeftDown() {
      if (!this.emptyValue) {
        this.calculateLeftPercent();
      } else {
        this.leftValue = Number(this.min);
        this.leftPercent = 0;
      }
    },
    inputRightDown() {
      if (!this.emptyValue) {
        this.calculateRightPercent();
      } else {
        this.rightValue = Number(this.max);
        this.rightPercent = 0;
      }
    },
    calculateLeftPercent() {
      this.leftValue = Math.min(this.leftValue, this.rightValue - this.step);
      const left = this.parsedMin < 0 ? this.leftValue - this.parsedMin : this.leftValue;
      const min = this.parsedMin < 0 ? 0 : this.parsedMin;
      const max = this.parsedMin < 0 ? this.parsedMax - this.parsedMin : this.parsedMax;
      this.leftPercent = ((left - min) / (max - min)) * 100;
    },
    calculateRightPercent() {
      this.rightValue = Math.max(this.rightValue, this.leftValue + this.step);
      this.rightPercent = 100 - ((this.rightValue - this.parsedMin) / (this.parsedMax - this.parsedMin)) * 100;
    },
    changeValue() {
      this.$emit('change', { min: this.leftValue, max: this.rightValue });
    },
  },
};
</script>
<style scoped>
.slider {
  z-index: 1;
  margin: 0 15px;
}
.slider > .track {
  z-index: 1;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 5px;
}
.slider > .range {
  position: absolute;
  z-index: 2;
  left: 0%;
  right: 0%;
  top: 0;
  bottom: 0;
  border-radius: 5px;
}
.slider > .thumb {
  z-index: 3;
  transition: box-shadow 0.3s ease-in-out;
}
.slider > .thumb.left {
  left: 25%;
  transform: translate(-15px, -10px);
}
.slider > .thumb.right {
  right: 25%;
  transform: translate(15px, -10px);
}
input[type='range'] {
  position: absolute;
  pointer-events: none;
  -webkit-appearance: none;
  z-index: 24;
  height: 10px;
  width: 100%;
  opacity: 0;
}
input[type='range']::-moz-range-thumb {
  pointer-events: all;
  width: 20px;
  height: 20px;
  border-radius: 0;
  border: 0 none;
  background-color: blue;
  -webkit-appearance: none;
  cursor: -webkit-grabbing;
  cursor: grabbing;
}
input[type='range']::-webkit-slider-thumb {
  cursor: -webkit-grabbing;
  cursor: grabbing;
  pointer-events: all;
  width: 30px;
  height: 30px;
  border-radius: 0;
  border: 0 none;
  background-color: red;
  -webkit-appearance: none;
}
#input-left::-moz-range-thumb {
  transform: translate(-5px, 0px);
}
#input-right::-moz-range-thumb {
  transform: translate(5px, 0px);
}
</style>
