<template>
  <div class="w-full flex items-center justify-center">
    <text-cell v-if="isTextCell" :value="value" />
    <numeric-cell v-else-if="isNumericCell" :value="value" remove-format-number />
    <contextual-segmentation-cell v-else-if="isSegmentationCell" :value="value" :row-data="rowData" />
    <saleforce-cell v-else-if="isSaleforceCell" :value="value" :row-data="rowData" />
    <span v-else class="bg-red-200">--{{ value }}</span>
  </div>
</template>

<script>
import TextCell from '@/components/atoms/AnalyticsTable/TextCell';
import ContextualSegmentationCell from '@/components/atoms/AnalyticsTable/ContextualSegmentationCell';
import SaleforceCell from '@/components/atoms/AnalyticsTable/SaleforceCell';
import NumericCell from '@/components/atoms/AnalyticsTable/NumericCell';
import { COLUMNS } from '@/views/private/modules/contextual/cluster/config';

export default {
  name: 'AnalyticsCellOrchestrator',
  components: { TextCell, ContextualSegmentationCell, SaleforceCell, NumericCell },
  data() {
    return {
      value: null,
      column: null,
      rowData: null,
    };
  },
  computed: {
    isTextCell() {
      // TODO rethink headers array each type (maybe in Column class)
      return [COLUMNS.NAME.value, 'ag-Grid-AutoColumn'].some(key => key === this.column);
    },
    isSegmentationCell() {
      return COLUMNS.SEGMENTATIONS.value === this.column;
    },
    isSaleforceCell() {
      return COLUMNS.OWNER_CLIENT.value === this.column;
    },
    isNumericCell() {
      return COLUMNS.CLUSTER_ID.value === this.column;
    },
  },
  beforeMount() {
    this.column = this.params.column.colId;
    this.rowData = this.params.data;
    this.value = this.params.value;
  },
};
</script>

<style scoped></style>
