import { api } from '.';
import { CLIENT_RESOURCE, CLUSTERS_RESOURCE } from '@/services/keys';

/**
 *
 * @param {string} clusterId
 * @returns {Promise<object>}
 */
export async function deleteCluster(clientId, clusterId) {
  const partialUrl = api.createUrl({
    [CLIENT_RESOURCE]: clientId,
    [CLUSTERS_RESOURCE]: clusterId,
  });
  const { data } = await api.delete(partialUrl);
  return data;
}
