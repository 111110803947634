<template>
  <section>
    <header>
      <nav>
        <ul class="flex space-x-2 border-b">
          <li
            v-for="tab in tabs"
            :key="tab.title"
            class="relative inline-block px-1 py-3 text-sm font-semibold text-gray-500 border-b-2 border-transparent cursor-pointer select-none transition ease-in-out duration-150 hover:border-orange-600 hover:text-orange-600 hover:text-gray-600 hover:border-gray-300"
            :class="{ 'text-orange-600 border-orange-600': active === tab.title }"
            @click="onClickTab(tab)"
          >
            {{ tab.title }}
          </li>
        </ul>
      </nav>
    </header>

    <div v-for="tab in tabs" :key="tab.title" class="py-4 px-2" :class="{ hidden: active !== tab.title }">
      <slot :name="tab?.id || tab.title.toLowerCase()" :tab="tab">
        {{ tab.title }}
      </slot>
    </div>
  </section>
</template>

<script>
export default {
  name: 'TabGroup',
  props: {
    tabs: { type: Array, default: () => [] },
  },
  data() {
    return {
      active: { type: String, default: '' },
    };
  },
  mounted() {
    this.active = this.tabs.at(0)?.title;
  },
  methods: {
    onClickTab(tab) {
      this.active = tab.title;
      this.$emit('change', tab);
    },
  },
};
</script>

<style scoped></style>
