<template>
  <span class="px-4 py-1 leading-tight align-middle inline-flex items-center rounded-full" :class="[bgColor]">
    <span class="text-xs font-bold whitespace-no-wrap" :class="[textColor]">
      <slot />
    </span>
  </span>
</template>

<script>
export default {
  name: 'SunPill',
  props: {
    color: {
      type: String,
      default: () => 'indigo',
    },
    bgIntensity: {
      type: String,
      default: () => '100',
    },
    textIntensity: {
      type: String,
      default: () => '900',
    },
  },
  computed: {
    bgColor() {
      return `bg-${this.color}-${this.bgIntensity}`;
    },
    textColor() {
      return `text-${this.color}-${this.textIntensity}`;
    },
  },
};
</script>
