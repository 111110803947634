<template>
  <div>
    <asterix-section>
      <template #header-title>{{ title }}</template>
      <template #content>
        <div class="w-full p-6 bg-white rounded-lg shadow-md lg:p-12 mb-12">
          <sun-form @submit="onFormSubmit">
            <form-row class="justify-around">
              <sun-label-group text="Cluster ID:" class="w-1/3 px-4">
                <line-loader v-if="isLoading" />
                <span v-else class="h-10 w-full flex items-center">{{ clusterId }}</span>
              </sun-label-group>
              <sun-label-group text="Cluster Name:" class="w-1/3 px-4">
                <line-loader v-if="isLoading" />
                <asterix-input
                  v-else
                  v-model="dataCluster.name"
                  auto-validate
                  name="clusterName"
                  placeholder="Cluster Name"
                  required="required"
                />
              </sun-label-group>
              <sun-label-group text="Salesforce Opportunity:" class="w-1/3 px-4">
                <asterix-async-select
                  name="salesforceOpportunity"
                  track-by="id"
                  label="name"
                  is-array-object
                  text-error="Salesforce Opportunity is already in use"
                  :error="salesforceSelectError"
                  close-on-select
                  :value="initialSfOpp"
                  :service="getSalesforceOpportunities"
                  @change="salesforceSelectError = false"
                />
              </sun-label-group>
            </form-row>
            <div class="flex mt-4 text-left p-5">
              <div class="w-1/2 border">
                <div class="w-full bg-orange-200">
                  <div class="font-bold p-3">Contextual</div>
                  <div class="w-full p-3 bg-white">
                    <tab-group key="contextual" :tabs="tabs.contextual" class="text-sm" @change="onChangeTab">
                      <template #categories>
                        <labels-section
                          :label-group="contextual.categories"
                          :segments-included="segmentsIncluded"
                          :segments-excluded="segmentsExcluded"
                          :is-loading="isLoading || contextual.categories.isLoading"
                          @include="optionManager(segmentTypes.INCLUDE, $event)"
                          @exclude="optionManager(segmentTypes.EXCLUDE, $event)"
                          @onChangePage="onChangePage($event)"
                          @search="onLabelSearch($event)"
                        />
                      </template>
                      <template #entities>
                        <labels-section
                          :label-group="contextual.entities"
                          :segments-included="segmentsIncluded"
                          :segments-excluded="segmentsExcluded"
                          :is-loading="isLoading || contextual.entities.isLoading"
                          @include="optionManager(segmentTypes.INCLUDE, $event)"
                          @exclude="optionManager(segmentTypes.EXCLUDE, $event)"
                          @onChangePage="onChangePage($event)"
                          @search="onLabelSearch($event)"
                        />
                      </template>
                      <template #publisher>
                        <labels-section
                          :label-group="contextual.publisherType"
                          :segments-included="segmentsIncluded"
                          :segments-excluded="segmentsExcluded"
                          :is-loading="isLoading || contextual.publisherType.isLoading"
                          @include="optionManager(segmentTypes.INCLUDE, $event)"
                          @exclude="optionManager(segmentTypes.EXCLUDE, $event)"
                          @onChangePage="onChangePage($event)"
                          @search="onLabelSearch($event)"
                        />
                      </template>
                      <template #sensitive>
                        <labels-section
                          :label-group="contextual.sensitive"
                          :segments-included="segmentsIncluded"
                          :segments-excluded="segmentsExcluded"
                          :is-loading="isLoading || contextual.sensitive.isLoading"
                          @include="optionManager(segmentTypes.INCLUDE, $event)"
                          @exclude="optionManager(segmentTypes.EXCLUDE, $event)"
                          @onChangePage="onChangePage($event)"
                          @search="onLabelSearch($event)"
                        />
                      </template>
                    </tab-group>
                  </div>
                </div>
                <div class="w-full bg-orange-200">
                  <div class="font-bold p-3">Image</div>
                  <div class="w-full p-3 bg-white">
                    <tab-group key="image" :tabs="tabs.image" class="text-sm" @change="onChangeTab">
                      <template #people>
                        <labels-section
                          :label-group="image.people"
                          :segments-included="segmentsIncluded"
                          :segments-excluded="segmentsExcluded"
                          :is-loading="isLoading || image.people.isLoading"
                          @include="optionManager(segmentTypes.INCLUDE, $event)"
                          @exclude="optionManager(segmentTypes.EXCLUDE, $event)"
                          @onChangePage="onChangePage($event)"
                          @search="onLabelSearch($event)"
                        />
                      </template>
                      <template #objects>
                        <labels-section
                          :label-group="image.objects"
                          :segments-included="segmentsIncluded"
                          :segments-excluded="segmentsExcluded"
                          :is-loading="isLoading || image.objects.isLoading"
                          @include="optionManager(segmentTypes.INCLUDE, $event)"
                          @exclude="optionManager(segmentTypes.EXCLUDE, $event)"
                          @onChangePage="onChangePage($event)"
                          @search="onLabelSearch($event)"
                        />
                      </template>
                      <template #places>
                        <labels-section
                          :label-group="image.places"
                          :segments-included="segmentsIncluded"
                          :segments-excluded="segmentsExcluded"
                          :is-loading="isLoading || image.places.isLoading"
                          @include="optionManager(segmentTypes.INCLUDE, $event)"
                          @exclude="optionManager(segmentTypes.EXCLUDE, $event)"
                          @onChangePage="onChangePage($event)"
                          @search="onLabelSearch($event)"
                        />
                      </template>
                      <template #logos>
                        <labels-section
                          :label-group="image.logos"
                          :segments-included="segmentsIncluded"
                          :segments-excluded="segmentsExcluded"
                          :is-loading="isLoading || image.logos.isLoading"
                          @include="optionManager(segmentTypes.INCLUDE, $event)"
                          @exclude="optionManager(segmentTypes.EXCLUDE, $event)"
                          @onChangePage="onChangePage($event)"
                          @search="onLabelSearch($event)"
                        />
                      </template>
                    </tab-group>
                  </div>
                </div>

                <div class="w-full bg-orange-200">
                  <div
                    class="font-bold p-3 cursor-pointer flex collapsible-container-header"
                    @click="openCloseWeather()"
                  >
                    <div class="flex-1 collapsible-container-title">Weather</div>
                    <sun-arrow-down-svg :class="{ 'rotate-180': isWeatherOpen }"></sun-arrow-down-svg>
                  </div>
                  <div v-if="isWeatherOpen" class="collapsible-container-body w-full p-3 bg-white">
                    <tab-group key="weather" :tabs="tabs.weather" class="text-sm">
                      <template #temp>
                        <double-range-slider-input
                          title-value="Temp"
                          :min-value="dataCluster.weather.tempMin"
                          :max-value="dataCluster.weather.tempMax"
                          min="-20"
                          max="45"
                          :empty-value="isTempEmpty"
                          @change="changeTemp"
                        />
                      </template>
                      <template #rain>
                        <double-range-slider-input
                          title-value="Rain"
                          :min-value="dataCluster.weather.rainMin"
                          :max-value="dataCluster.weather.rainMax"
                          :step="10"
                          :empty-value="isRainEmpty"
                          @change="changeRain"
                        />
                      </template>
                      <template #pollution>
                        <double-range-slider-input
                          title-value="Pollution"
                          :min-value="dataCluster.weather.pollutionMin"
                          :max-value="dataCluster.weather.pollutionMax"
                          :empty-value="isPollutionEmpty"
                        />
                      </template>
                    </tab-group>
                  </div>
                </div>
              </div>
              <div class="w-1/2 p-2 border border-gray-200 flex flex-col">
                <div class="flex justify-between mb-5">
                  <div id="left" class="flex space-x-2">
                    <sun-button class="text-xs custom-p-1" :color="operatorAndColor" @click="activeOperator = 'AND'">
                      AND
                    </sun-button>
                    <sun-button class="text-xs custom-p-1" :color="operatorOrColor" @click="activeOperator = 'OR'">
                      OR
                    </sun-button>
                  </div>
                  <div id="right">
                    <sun-button
                      class="text-xs custom-p-1"
                      color="orange"
                      variant="pill"
                      :disabled="isLoading"
                      @click="onNewGroup"
                    >
                      + New Group
                    </sun-button>
                  </div>
                </div>
                <div v-if="isLoading">
                  <line-loader class="rounded mb-2" />
                  <line-loader class="rounded mb-2" />
                  <line-loader class="rounded mb-2" />
                </div>
                <div v-else>
                  <div v-for="(group, index) in groups" :key="`group_${index}`" class="w-full flex-col group-list">
                    <div
                      class="border w-full flex flex-col p-2 rounded group cursor-pointer hover:shadow"
                      :class="{ 'border-orange-600 shadow': index === groupActive }"
                      @click="setGroupActive(index)"
                    >
                      <div class="flex justify-between pb-2">
                        <div class="flex w-full">
                          <h3 class="text-m font-bold mr-2">Name:</h3>
                          <input v-model="group.id" class="w-full mr-2 shadow px-2 rounded" />
                        </div>
                        <button @click.stop="onDeleteGroup(index)"><trash-svg class="w-5 h-5" /></button>
                      </div>

                      <div v-for="segment in group.labels" :key="segment.id" class="pl-2 flex flex-col segment">
                        <span class="pl-2">
                          <span class="text-red-500">{{
                            segment.condition === segmentTypes.EXCLUDE ? 'NOT' : ''
                          }}</span>
                          {{ segment.label }}
                        </span>
                        <span class="operator operatorType">"{{ activeOperator }}"</span>
                      </div>
                    </div>
                    <div class="group-operator my-2 operatorType">"{{ counterOperator }}"</div>
                  </div>
                </div>
                <div class="flex flex-col w-full mt-5 pt-5 border-t">
                  <div class="w-full font-bold mb-2">Weather:</div>
                  <div class="flex w-full p-2">
                    Temperature:
                    <line-loader v-if="isLoading" class="rounded ml-2 max-w-xs" />
                    <div v-else class="ml-2">
                      <span v-if="hasTempSet">{{ temp }}</span>
                      <span v-else>{{ naWeatherPropData }}</span>
                    </div>
                  </div>
                  <div class="flex w-full p-2">
                    Rain:
                    <line-loader v-if="isLoading" class="rounded ml-2 max-w-xs" />
                    <div v-else class="ml-2">
                      <span v-if="hasRainSet">{{ rain }}</span>
                      <span v-else>{{ naWeatherPropData }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex flex-wrap justify-end sm:px-3">
              <save-button id="cluster-submit" :loading="isSaving" class="mb-3 sm:mb-0 button" />
              <sun-button
                variant="pill"
                class="w-full text-sm custom-p-1 ml-2 sm:w-auto"
                color="white"
                @click="goToList"
              >
                Cancel
              </sun-button>
            </div>
          </sun-form>
        </div>
      </template>
    </asterix-section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import AsterixSection from '@/components/templates/AsterixSection';
import AsterixInput from '@/components/atoms/AsterixInput.vue';
import FormRow from '@/components/atoms/FormRow/FormRow';
import SaveButton from '@/components/atoms/SaveButton.vue';
import IncludeItem from '@/model/shared/IncludeItem';
import TabGroup from '@/components/organisms/shared/TabGroup';
import { getLabelList } from '@/services/modules/contextual/label/getLabelList';
import { CONFIG, segmentTypes } from './config.js';
import { deepClone } from '@/utils/deepClone';
import TrashSvg from '@/components/icons/TrashSvg';
import { getClusterById, createCluster, updateCluster } from '@/services/modules/contextual/cluster';
import Cluster from '@/entities/contextual/Cluster';
import AsterixAsyncSelect from '@/components/molecules/shared/AsterixAsyncSelect';
import { CREATE_TOAST } from '@/store/modules/toast/keys.js';
import { Toast } from '@/model/shared/Toast';
import { dateWithoutTimeFormat } from '@/utils/dateTime/date';
import { list as clusterList } from '@/router/private/modules/contextual/cluster/list';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';
import LineLoader from '@/components/atoms/Loaders/LineLoader.vue';
import DoubleRangeSliderInput from '@/components/atoms/DoubleRangeSliderInput.vue';
import { ACTIVE_CLIENT } from '@/store/modules/context/keys';
import LabelsSection from '@/components/organisms/modules/contextual/clusterEdit/LabelsSection.vue';
import LabelGroup from '@/entities/contextual/LabelGroup';
import { getSalesforceOpportunities } from '@/services/modules/contextual/salesforce/getSalesforceOpportunities';

export default {
  name: 'ClusterEdit',
  components: {
    AsterixSection,
    AsterixInput,
    FormRow,
    SaveButton,
    TabGroup,
    TrashSvg,
    AsterixAsyncSelect,
    LineLoader,
    DoubleRangeSliderInput,
    LabelsSection,
  },
  data() {
    return {
      segmentTypes,
      contextual: {
        categories: new LabelGroup(CONFIG.GROUP_TYPES.CATEGORIES).addFilters({
          type: 'type',
          value: 'iabTier1,IabTier2,IabTier3',
        }),
        entities: new LabelGroup(CONFIG.GROUP_TYPES.ENTITIES).addFilters({
          type: 'type',
          value: 'entities',
        }),
        publisherType: new LabelGroup(CONFIG.GROUP_TYPES.PUBLISHER).addFilters({
          type: 'type',
          value: 'publishertype',
        }),
        sensitive: new LabelGroup(CONFIG.GROUP_TYPES.SENSITIVE).addFilters({
          type: 'type',
          value: 'sensitive',
        }),
      },
      image: {
        people: new LabelGroup(CONFIG.GROUP_TYPES.PEOPLE).addFilters({
          type: 'type',
          value: 'imgHumans',
        }),
        logos: new LabelGroup(CONFIG.GROUP_TYPES.LOGOS).addFilters({
          type: 'type',
          value: 'imgLogos',
        }),
        objects: new LabelGroup(CONFIG.GROUP_TYPES.OBJECTS).addFilters({
          type: 'type',
          value: 'imgObjectDetection',
        }),
        places: new LabelGroup(CONFIG.GROUP_TYPES.PLACES).addFilters({
          type: 'type',
          value: 'imgPlaces',
        }),
      },
      tabs: CONFIG.segmentationTabs,
      activeOperator: 'AND',
      min: 0,
      max: 10,
      isWeatherOpen: false,
      groups: [],
      dataCluster: {},
      groupActive: 0,
      groupAddedCount: 1,
      isSaving: false,
      initialSfOpp: {},
      isLoading: false,
      naWeatherPropData: 'N/A - N/A',
      salesforceSelectError: false,
    };
  },
  computed: {
    ...mapGetters({
      activeClient: ACTIVE_CLIENT,
    }),
    clusterId() {
      return this.$route.params.clusterId;
    },
    isTempEmpty() {
      return this.dataCluster.weather.tempMin === null || this.dataCluster.weather.tempMax === null;
    },
    isRainEmpty() {
      return this.dataCluster.weather.rainMin === null || this.dataCluster.weather.rainMax === null;
    },
    isPollutionEmpty() {
      return this.dataCluster.weather.pollutionMin === null || this.dataCluster.weather.pollutionMax === null;
    },
    operatorAndColor() {
      return this.activeOperator === 'AND' ? 'orange' : 'gray';
    },
    operatorOrColor() {
      return this.activeOperator === 'OR' ? 'orange' : 'gray';
    },
    counterOperator() {
      return this.activeOperator === 'AND' ? 'OR' : 'AND';
    },
    temp() {
      return `${this.dataCluster.weather.tempMin} - ${this.dataCluster.weather.tempMax}`;
    },
    rain() {
      return `${this.dataCluster.weather.rainMin} - ${this.dataCluster.weather.rainMax}`;
    },
    pollution() {
      return `${this.dataCluster.weather.pollutionMin} - ${this.dataCluster.weather.pollutionMax}`;
    },
    segmentsIncluded() {
      return this.filterSegments(this.segmentTypes.INCLUDE);
    },
    segmentsExcluded() {
      return this.filterSegments(this.segmentTypes.EXCLUDE);
    },
    hasWeatherSet() {
      return this.hasTempSet || this.hasRainSet || this.hasPollutionSet;
    },
    hasTempSet() {
      return this.dataCluster.weather?.tempMin || this.dataCluster.weather?.tempMax;
    },
    hasRainSet() {
      return this.dataCluster.weather?.rainMin || this.dataCluster.weather?.rainMax;
    },
    hasPollutionSet() {
      return this.dataCluster.weather?.pollutionMin || this.dataCluster.weather?.pollutionMax;
    },
    title() {
      return `${this.clusterId ? 'Edit' : 'New'} Cluster`;
    },
  },
  async mounted() {
    this.isLoading = true;
    await this.loadLabels(this.contextual.categories);
    await this.loadLabels(this.image.people);
    await this.loadInitialCluster();
    this.isWeatherOpen = this.hasWeatherSet;
    this.isLoading = false;
  },
  methods: {
    ...mapActions({
      createToast: CREATE_TOAST,
    }),
    getSalesforceOpportunities,
    async loadInitialCluster() {
      if (this.$route.params.cluster) {
        this.dataCluster = deepClone(this.$route.params.cluster);
      } else if (this.$route.params.clusterId) {
        this.dataCluster = await getClusterById(this.activeClient.id, this.clusterId);
        this.dataCluster = this.dataCluster.data;
      } else {
        this.dataCluster = new Cluster();
      }
      if (this.dataCluster?.segmentations) {
        this.groups = this.dataCluster.segmentations.groups || [];
        this.activeOperator = this.dataCluster.segmentations.operator === 'and' ? 'OR' : 'AND';
        this.groupAddedCount = this.groups.length + 1;
      }
      this.initialSfOpp = {
        advertiserName: this.dataCluster.sfAdvertiser || null,
        brandName: this.dataCluster.sfBrand || null,
        office: this.dataCluster.sfOffice || null,
        id: this.dataCluster.sfOppId || null,
        name: this.dataCluster.sfName || null,
      };
    },
    optionManager(condition, option) {
      const optionClone = deepClone(option);
      optionClone.condition = condition;
      this.addSegmentToActiveGroup(optionClone);
    },
    addSegmentToActiveGroup(optionClone) {
      if (this.groups.length === 0) {
        this.groups.push({ id: 'group 1', labels: [] });
        this.groupAddedCount++;
      }
      const groupToEdit = this.groups[this.groupActive];
      const alreadyExists = groupToEdit.labels.find(segment => segment.uid === optionClone.id);

      if (alreadyExists) {
        if (alreadyExists.condition === optionClone.condition) {
          groupToEdit.labels = groupToEdit.labels.filter(segment => segment.uid !== optionClone.id);
        } else {
          alreadyExists.condition = optionClone.condition;
        }
      } else {
        groupToEdit.labels.push({
          uid: optionClone.id,
          type: optionClone.type,
          label: optionClone.name,
          condition: optionClone.condition,
        });
      }
    },
    onNewGroup() {
      this.groups.push({ id: `group ${this.groupAddedCount}`, labels: [] });
      this.groupActive = this.groups.length - 1;
      this.groupAddedCount++;
    },
    onDeleteGroup(groupIndex) {
      this.groups.splice(groupIndex, 1);
      if (groupIndex === this.groupActive) this.setGroupActive(0);
      this.groupAddedCount--;
    },
    async onFormSubmit(form) {
      this.isSaving = true;
      if (form.valid) {
        const sfOpp = form.form.salesforceOpportunity.value || {};
        const clName = form.form.clusterName.value;
        const segmentations = this.formatSegmentations();
        const date = dateWithoutTimeFormat(new Date());
        const clusterToSend = new Cluster(
          this.clusterId,
          clName,
          sfOpp.office || null,
          sfOpp.brandName || null,
          sfOpp.advertiserName || null,
          sfOpp.id || CONFIG.SUNMEDIA_GENERAL,
          sfOpp.id ? sfOpp.name || null : CONFIG.SUNMEDIA_GENERAL,
          this.dataCluster.weather,
          segmentations,
          ''
        );
        clusterToSend.date = date;
        try {
          if (this.clusterId) {
            await updateCluster(this.activeClient.id, clusterToSend);
            this.createToast(Toast.success('Success!', 'Cluster Edited'));
          } else {
            await createCluster(this.activeClient.id, clusterToSend);
            this.createToast(Toast.success('Success!', 'Cluster created'));
          }
          this.goToList();
        } catch (e) {
          if (e === 'SfUsed') {
            this.salesforceSelectError = true;
            this.createToast(Toast.error('ERROR', 'The saleforce opportunity is already in use'));
          } else {
            this.createToast(Toast.error('ERROR', 'Something went wrong'));
          }
        }
      }
      this.isSaving = false;
    },
    formatSegmentations() {
      const groupsWithOperator = this.groups.map(group => {
        return { operator: this.activeOperator.toLocaleLowerCase(), ...group };
      });
      return {
        operator: this.counterOperator.toLowerCase(),
        groups: groupsWithOperator,
      };
    },
    openCloseWeather() {
      this.isWeatherOpen = !this.isWeatherOpen;
    },
    setGroupActive(index) {
      this.groupActive = index;
    },
    goToList() {
      this.$router.push({ name: clusterList.name });
    },
    async loadLabels(configLabel, filters, page = 1) {
      try {
        if (filters) {
          configLabel.filters = configLabel.filters.concat(filters);
        }

        configLabel.isLoading = true;
        const params = new QueryParamsBuilder();
        configLabel.filters.forEach(filter => {
          params.addFilter(filter.type, filter.value);
        });
        params.page = page || configLabel.page.current;
        const labelsSearched = await getLabelList(params);
        configLabel.options = [];
        labelsSearched.data.forEach(label => {
          const includeItem = new IncludeItem(label.id, label.name);
          includeItem.type = label.type;
          configLabel.options.push(includeItem);
        });
        configLabel.page.total = labelsSearched.meta.totalPages;
        configLabel.page.current = params.page;
      } catch (e) {
        this.createToast(Toast.error('ERROR', 'Something went wrong'));
      } finally {
        configLabel.isLoading = false;
      }
    },
    changeTemp(values) {
      this.dataCluster.weather.tempMin = values.min;
      this.dataCluster.weather.tempMax = values.max;
    },
    changeRain(values) {
      this.dataCluster.weather.rainMin = values.min;
      this.dataCluster.weather.rainMax = values.max;
    },
    switchTabs(option, page, filters) {
      switch (option) {
        case CONFIG.GROUP_TYPES.CATEGORIES: {
          this.loadLabels(this.contextual.categories, filters, page);
          break;
        }
        case CONFIG.GROUP_TYPES.ENTITIES: {
          this.loadLabels(this.contextual.entities, filters, page);
          break;
        }
        case CONFIG.GROUP_TYPES.PUBLISHER: {
          this.loadLabels(this.contextual.publisherType, filters, page);
          break;
        }
        case CONFIG.GROUP_TYPES.SENSITIVE: {
          this.loadLabels(this.contextual.sensitive, filters, page);
          break;
        }
        case CONFIG.GROUP_TYPES.PEOPLE: {
          this.loadLabels(this.image.people, filters, page);
          break;
        }
        case CONFIG.GROUP_TYPES.OBJECTS: {
          this.loadLabels(this.image.objects, filters, page);
          break;
        }
        case CONFIG.GROUP_TYPES.PLACES: {
          this.loadLabels(this.image.places, page, filters);
          break;
        }
        case CONFIG.GROUP_TYPES.LOGOS: {
          this.loadLabels(this.image.logos, filters, page);
          break;
        }
      }
    },
    onChangeTab(tab) {
      this.switchTabs(tab.title);
    },
    onChangePage({ type, page }) {
      this.switchTabs(type, page);
    },
    onLabelSearch({ value, option }) {
      const nameFilter = { type: 'name', value };
      this.switchTabs(option, 1, nameFilter);
    },
    filterSegments(segmentTypes) {
      const segments = [];
      if (this.groups.length === 0) return segments;
      this.groups[this.groupActive].labels.forEach(segment => {
        if (segment.condition === segmentTypes) {
          segments.push({ id: segment.uid, name: segment.label });
        }
      });
      return segments;
    },
  },
};
</script>
<style scoped>
.segment:last-child .operator {
  display: none;
}
.group-list:last-child .group-operator {
  display: none;
}
.operatorType {
  @apply italic;
}
.group:nth-child(this.groupActive) {
  @apply bg-green-300;
}
</style>
