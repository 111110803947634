<template>
  <span v-if="isSunmediaGeneral" class="truncate w-full text-left capitalize" :title="value">
    {{ value || 'N/A' }}
  </span>
  <span
    v-else
    class="truncate w-full text-left underline cursor-pointer capitalize"
    :title="value"
    @click="goToSaleforce"
  >
    {{ value || 'N/A' }}
  </span>
</template>

<script>
import { SUNMEDIA_GENERAL } from '@/views/private/modules/contextual/cluster/config';

export default {
  name: 'SaleforceCell',
  props: {
    value: {
      type: [String, null],
      default: () => '',
    },
    rowData: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    isSunmediaGeneral() {
      return this.value === SUNMEDIA_GENERAL;
    },
  },
  methods: {
    goToSaleforce() {
      const url = `https://sunmedia.lightning.force.com/lightning/r/KS_Opportunity__c/${this.rowData.sfOpId}/view`;
      window.open(url, '_blank').focus();
    },
  },
};
</script>

<style scoped></style>
