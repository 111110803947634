import contextualEntityParser from '@/entities/contextual/ContextualEntityParser';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';
import ResponseBuilder from '@/model/shared/ResponseBuilder';
import { CLIENT_RESOURCE, CLUSTERS_RESOURCE } from '@/services/keys';
import { api } from '.';

export async function getClusterById(clientId, clusterId, params = new QueryParamsBuilder()) {
  const partialUrl = api.createUrl({
    [CLIENT_RESOURCE]: clientId,
    [CLUSTERS_RESOURCE]: clusterId,
  });
  const { data } = await api.get(partialUrl, params.buildWithoutPage());
  return new ResponseBuilder(contextualEntityParser.parse(data), data.meta).build();
}
