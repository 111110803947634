<template>
  <div class="w-full h-full items-center justify-center flex">
    <table-action-menu v-if="!clusterToDelete" :actions="actions" @click="onActionClick($event)"></table-action-menu>
    <div v-else class="flex flex-col w-full h-full">
      <span class="p-0 h-3 line-height-none">Are you sure?</span>
      <span class="p-2 h-3 line-height-none flex justify-between">
        <button
          :disabled="isDeleting"
          class="text-green-700 hover:text-green-500 cursor-pointer"
          @click="confirmDeleteModal"
        >
          <check-svg class="w-4 h-4" />
        </button>
        <button
          :disabled="isDeleting"
          class="text-red-700 hover:text-red-500 cursor-pointer"
          @click="clusterToDelete = null"
        >
          <exclude-svg class="w-4 h-4" />
        </button>
      </span>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import TableActionMenu from '@/components/organisms/shared/TableActionMenu.vue';
import { edit as clusterEdit } from '@/router/private/modules/contextual/cluster/edit';
import { stats as clusterStats } from '@/router/private/modules/contextual/cluster/stats';
import { deleteCluster } from '@/services/modules/contextual/cluster';
import { CREATE_TOAST } from '@/store/modules/toast/keys.js';
import { Toast } from '@/model/shared/Toast';
import CheckSvg from '@/components/icons/CheckSvg';
import ExcludeSvg from '@/components/icons/ExcludeSvg';
import { USER } from '@/store/modules/auth/keys';
import { ACTIVE_CLIENT, ACTIVE_CONTEXT } from '@/store/modules/context/keys';
import { ROLES } from '@/model/shared/roles';

export default {
  name: 'ActionContextualCell',
  components: { TableActionMenu, CheckSvg, ExcludeSvg },
  data() {
    return {
      api: null,
      clusterToDelete: null,
      rowData: null,
      isDeleting: false,
    };
  },
  computed: {
    ...mapGetters({
      user: USER,
      contextActive: ACTIVE_CONTEXT,
      activeClient: ACTIVE_CLIENT,
    }),
    userRole() {
      return this.user.contextRoles.find(role => role.context === this.contextActive.id).role;
    },
    isSalesRole() {
      return this.userRole === ROLES.SALES.id;
    },
    isAdOpsRole() {
      return this.userRole === ROLES.AD_OPS.id;
    },
    modalDeleteDescription() {
      return `You are going to remove ${this.clusterToDelete?.name}. This action can not be undone.`;
    },
    actions() {
      const actions = [];
      if (this.isSalesRole) {
        actions.push({ name: 'Stats' });
      } else if (this.isAdOpsRole) {
        actions.push({ name: 'Edit' });
      } else if (!this.isSalesRole && !this.isAdOpsRole) {
        actions.push({ name: 'Delete' });
        actions.push({ name: 'Edit' });
        actions.push({ name: 'Stats' });
      }
      return actions;
    },
  },
  beforeMount() {
    this.api = this.params.api;
    this.rowData = this.params.data;
  },
  methods: {
    ...mapActions({
      createToast: CREATE_TOAST,
    }),
    onActionClick(event) {
      if (event.name === 'Delete') {
        this.clusterToDelete = this.rowData;
      } else {
        const target = {
          name: event.name === 'Edit' ? clusterEdit.name : clusterStats.name,
          params: { clusterId: this.rowData.id, cluster: this.rowData },
        };
        this.$router.push(target);
      }
      this.$emit('actionClick', { type: event, data: this.rowData });
    },
    async confirmDeleteModal() {
      this.isDeleting = true;
      await deleteCluster(this.activeClient.id, this.clusterToDelete.id);
      this.clusterToDelete = null;
      this.isDeleting = false;
      this.createToast(Toast.success('Success!', 'Template deleted'));
      this.api.purgeServerSideCache();
    },
  },
};
</script>
<style scoped>
.line-height-none {
  line-height: 1.5;
}
button:disabled {
  @apply cursor-not-allowed text-gray-500;
}
</style>
