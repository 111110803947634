import { api } from '.';
import { CLIENT_RESOURCE, CLUSTERS_RESOURCE } from '@/services/keys';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';
import Cluster from '@/entities/contextual/Cluster';

/**
 *
 * @param {QueryParamsBuilder} params
 * @returns {ResponseBuilder<{data:Cluster[],meta:Object}>}
 */
export async function getClusters(clientId, params = new QueryParamsBuilder()) {
  const partialUrl = api.createUrl({
    [CLIENT_RESOURCE]: clientId,
    [CLUSTERS_RESOURCE]: undefined,
  });
  const { data } = await api.get(partialUrl, params.build());
  const clusters = data.data.map(cluster => Cluster.create({ attributes: cluster }));
  return { data: clusters, meta: data.meta };
}
