<template>
  <asterix-section :status="sectionStatus">
    <template v-if="!isSalesRole" #header-right>
      <router-link :to="newClusterRoute()">
        <sun-button variant="pill" class="px-4 custom-p-1 text-xs bg-gray-700 hover:bg-gray-800" color="gray">
          + New Cluster
        </sun-button>
      </router-link>
    </template>
    <template #content>
      <ag-grid-vue
        :key="`ClusterTable_${tableKey}`"
        style="height: 500px"
        class="ag-theme-alpine"
        :column-defs="headers"
        :column-types="columnTypes"
        pagination
        animate-rows
        server-side-infinite-scroll
        :row-model-type="rowModelType"
        :suppress-agg-func-in-header="true"
        :default-col-def="defaultColDef"
        :pagination-page-size="paginationPageSize"
        :cache-block-size="cacheBlockSize"
        @grid-ready="onGridReady"
      />
    </template>
  </asterix-section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { AgGridVue } from 'ag-grid-vue';
import AsterixSection from '@/components/templates/AsterixSection';
import { CONFIG, COLUMNS } from './config.js';
import { SECTION_STATUS } from '@/model/shared/sectionStatus';
import AnalyticsCellOrchestrator from '@/components/organisms/modules/contextual/AnalyticsCellOrchestrator.vue';
import ActionContextualCell from '@/components/atoms/AnalyticsTable/ActionContextualCell';
import { CREATE_TOAST } from '@/store/modules/toast/keys.js';
import { getClusters } from '@/services/modules/contextual/cluster';
import { indexMixin } from '@/mixins/index/indexMixin';
import { create as clusterCreate } from '@/router/private/modules/contextual/cluster/create';
import { ACTIVE_CLIENT, ACTIVE_CONTEXT } from '@/store/modules/context/keys';
import { USER } from '@/store/modules/auth/keys';
import { ROLES } from '@/model/shared/roles';

export default {
  name: 'ClusterList',
  components: {
    AsterixSection,
    AgGridVue,
    // eslint-disable-next-line vue/no-unused-components
    AnalyticsCellOrchestrator,
    // eslint-disable-next-line vue/no-unused-components
    ActionContextualCell,
  },
  mixins: [indexMixin],
  data: () => ({
    sectionStatus: SECTION_STATUS.OK,
    headers: CONFIG.headers,
    columnTypes: CONFIG.columnTypes,
    defaultColDef: CONFIG.defaultColDef,
    rowModelType: 'serverSide',
    gridApi: null,
    columnApi: null,
    paginationPageSize: 25,
    cacheBlockSize: 25,
    tableKey: 0,
  }),
  computed: {
    ...mapGetters({
      user: USER,
      contextActive: ACTIVE_CONTEXT,
      activeClient: ACTIVE_CLIENT,
    }),
    userRole() {
      return this.user.contextRoles.find(role => role.context === this.contextActive.id).role;
    },
    isSalesRole() {
      return this.userRole === ROLES.SALES.id;
    },
  },
  created() {
    window.addEventListener('resize', this.resizeColumns);
  },
  destroyed() {
    window.removeEventListener('resize', this.resizeColumns);
  },
  methods: {
    ...mapActions({
      createToast: CREATE_TOAST,
    }),
    onGridReady({ api, columnApi }) {
      this.gridApi = api;
      this.gridColumnApi = columnApi;
      this.resizeColumns();
      api.setServerSideDatasource({
        getRows: async params => {
          this.gridApi.hideOverlay();
          this.gridApi.showLoadingOverlay();
          try {
            const auxParams = this.setParamsToTable();
            auxParams.page = this.currentPage;
            auxParams.itemsPerPage = 1000;
            auxParams.addSort(COLUMNS.CLUSTER_ID.value, 'DESC');
            const clusters = await getClusters(this.activeClient.id, auxParams);
            const data = clusters.data;
            params.success({
              rowData: data,
              rowCount: -1,
            });
          } catch (e) {
            params.fail();
            throw e;
          } finally {
            if (this.gridApi.getDisplayedRowCount() === 0) {
              this.gridApi.showNoRowsOverlay();
            } else {
              this.gridApi.hideOverlay();
            }
          }
        },
      });
    },
    resizeColumns() {
      if (this.gridApi) {
        setTimeout(() => {
          this.gridApi.sizeColumnsToFit();
        });
      }
    },
    newClusterRoute() {
      return { name: clusterCreate.name };
    },
  },
};
</script>
<style scoped>
@import '~ag-grid-community/dist/styles/ag-grid.css';
@import '~ag-grid-community/dist/styles/ag-theme-alpine.css';

:deep(.ag-theme-alpine .ag-root-wrapper) {
  border: 0;
}
:deep(.ag-theme-alpine .ag-header) {
  @apply bg-gray-300 border-0;
}
:deep(.ag-theme-alpine .ag-header-cell) {
  @apply px-1;
}

:deep(.ag-theme-alpine .ag-header-cell-label),
:deep(.ag-theme-alpine .ag-cell-value) {
  @apply text-xs px-1;
}
:deep(.ag-theme-alpine .ag-watermark) {
  opacity: 0 !important;
  display: none !important;
  position: absolute !important;
  top: -9999px !important;
  left: -9999px !important;
  right: auto !important;
  bottom: auto !important;
  visibility: hidden !important;
  transition: none !important;
}
</style>
