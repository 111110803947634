var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    { staticClass: "group-item" },
    [
      _c("span", { staticClass: "font-bold" }, [
        _vm._v(" " + _vm._s(_vm.group.id) + " "),
      ]),
      _vm._v(" { "),
      _vm._l(_vm.group.labels, function (label, i) {
        return _c("span", { key: label.uid, staticClass: "label" }, [
          _c("span", [_vm._v(_vm._s(label.label))]),
          i < _vm.group.labels.length - 1
            ? _c(
                "span",
                { staticClass: "italic font-medium uppercase operator" },
                [_vm._v(" " + _vm._s(_vm.group.operator) + " ")]
              )
            : _vm._e(),
        ])
      }),
      _vm._v(" } "),
      _c("span", { staticClass: "italic font-medium uppercase operator" }, [
        _vm._v(" " + _vm._s(_vm.operator) + " "),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }