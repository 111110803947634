var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "w-full h-full items-center justify-center flex" },
    [
      !_vm.clusterToDelete
        ? _c("table-action-menu", {
            attrs: { actions: _vm.actions },
            on: {
              click: function ($event) {
                return _vm.onActionClick($event)
              },
            },
          })
        : _c("div", { staticClass: "flex flex-col w-full h-full" }, [
            _c("span", { staticClass: "p-0 h-3 line-height-none" }, [
              _vm._v("Are you sure?"),
            ]),
            _c(
              "span",
              { staticClass: "p-2 h-3 line-height-none flex justify-between" },
              [
                _c(
                  "button",
                  {
                    staticClass:
                      "text-green-700 hover:text-green-500 cursor-pointer",
                    attrs: { disabled: _vm.isDeleting },
                    on: { click: _vm.confirmDeleteModal },
                  },
                  [_c("check-svg", { staticClass: "w-4 h-4" })],
                  1
                ),
                _c(
                  "button",
                  {
                    staticClass:
                      "text-red-700 hover:text-red-500 cursor-pointer",
                    attrs: { disabled: _vm.isDeleting },
                    on: {
                      click: function ($event) {
                        _vm.clusterToDelete = null
                      },
                    },
                  },
                  [_c("exclude-svg", { staticClass: "w-4 h-4" })],
                  1
                ),
              ]
            ),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }