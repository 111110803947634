var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "flex flex-col mt-2 sm:mt-0 sm:w-1/4 p-4 border border-r-0",
    },
    [
      _c("p", { staticClass: "text-sm font-bold text-left text-gray-600" }, [
        _vm._v(_vm._s(_vm.title)),
      ]),
      _vm.isLoading
        ? _c("div", { staticClass: "flex justify-between mt-5" }, [
            _c("div", {
              staticClass: "bg-gray-300 animate-pulse rounded h-6 w-32 py-1",
            }),
            _c("div", {
              staticClass:
                "bg-gray-300 animate-pulse rounded-full h-6 w-10 py-1",
            }),
          ])
        : _c(
            "div",
            { staticClass: "flex justify-between mt-5" },
            [
              _vm.textValue
                ? _c("span", { staticClass: "font-bold text-xl" }, [
                    _vm._v(_vm._s(_vm._f("numFormatEs")(_vm.textValue))),
                  ])
                : _c("span", { staticClass: "font-bold text-xl" }, [
                    _vm._v("N/A"),
                  ]),
              _vm.pillValue !== null
                ? _c(
                    "sun-pill",
                    {
                      attrs: {
                        color: _vm.pillColor,
                        "bg-intensity": "100",
                        "text-intensity": "600",
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "flex items-center" },
                        [
                          _vm.isEqual
                            ? _c("span", { staticClass: "w-4 h-4 mr-1" }, [
                                _vm._v("="),
                              ])
                            : _c("arrow-downward-svg", {
                                staticClass: "w-4 h-4 mr-1",
                                class: { "rotate-180": _vm.isPosive },
                              }),
                          _c("span", [
                            _vm._v(_vm._s(_vm._f("percentage")(_vm.pillValue))),
                          ]),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }