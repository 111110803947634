<template>
  <span class="truncate w-full text-left">
    <sun-popover position="right" :delay-hide="0" :delay-show="0">
      <segmentation-group-inline v-for="(group, index) in groups" :key="index" :group="group" :operator="operator" />
      <span v-if="showWeather"><span class="font-bold">weather</span> {{ weatherLabel }} </span>
      <template #popover>
        <div class="bg-white text-xs text-gray-800 px-2 py-1 border border-gray-400 rounded shadow-lg grid grid-cols-1">
          <segmentation-group-inline
            v-for="(group, index) in groups"
            :key="index"
            :group="group"
            :operator="operator"
            class="my-1"
          />
          <span class="my-1"> <span class="font-bold">weather</span> {{ weatherLabel }} </span>
        </div>
      </template>
    </sun-popover>
  </span>
</template>

<script>
import SegmentationGroupInline from '@/components/organisms/modules/contextual/SegmentationGroupInline.vue';

export default {
  name: 'ContextualSegmentationCell',
  components: { SegmentationGroupInline },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    rowData: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    groups() {
      return this.value?.groups || [];
    },
    operator() {
      return this.value.operator;
    },
    weatherLabel() {
      return `{ rain: ${this.rowData.weather.rainMin || 'N/A'} -> ${
        this.rowData.weather.rainMax || 'N/A'
      }, temperature: ${this.rowData.weather.tempMin || 'N/A'} -> ${this.rowData.weather.tempMax || 'N/A'} }`;
    },
    showWeather() {
      return (
        this.rowData.weather.rainMin ||
        this.rowData.weather.rainMax ||
        this.rowData.weather.tempMin ||
        this.rowData.weather.tempMax
      );
    },
  },
};
</script>
